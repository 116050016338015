import { webAssetUrl } from 'lib/utils/imageUtils';

const NotFoundPage = () => (
  <div
    className="relative bg-orange text-white text-xl sm:text-2xl overflow-hidden w-full h-full bg-center bg-no-repeat bg-cover"
    style={{ backgroundImage: `url(${webAssetUrl('error-303.png')})` }}
  >
    <div className="max-w-screen-lg m-auto px-4 py-12">
      <div className="text-center sm:text-left sm:w-2/3">
        <h1 className="text-3xl sm:text-4xl font-bold mb-4">
          ERROR &quot;303&quot;*
        </h1>
        <h2 className="mb-2">
          Oops. This page does not exist.
        </h2>
        <div className="mb-2">
          Still, we wouldn&apos;t blame you if you wanted to hang here for a bit.
          The serenity of nonexistence is unmatched.
        </div>
        <div className="text-lg">
          *It&apos;s a Denver thing.
        </div>
      </div>
    </div>
  </div>
);

export default NotFoundPage;
